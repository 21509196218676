/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'play': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13 24c6.075 0 11-4.925 11-11S19.075 2 13 2 2 6.925 2 13s4.925 11 11 11zm0 2c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13z" _fill="#fff"/><path pid="1" d="M10.833 9.1v7.8L16.9 13l-6.067-3.9z" _fill="#fff"/>'
  }
})
